import * as React from 'react';
import { errorReport } from '../../utils/errReport';

export function ImgWithErrReport(props) {
  return (
    <img
      onError={e => {
        errorReport('image load error', {
          extra: {
            imgUrl: props.src,
            originErr: e,
            meta: props.meta,
          },
          tags: {
            componentName: 'image',
          },
        });
      }}
      {...props}
    />
  );
}
