import './index.scss';
import * as React from 'react';
import { EmptyData } from '../../../lib/components/EmptyData';
import LazyLoadImage from '../../../lib/components/LazyLoadImage';
import { ImgWithErrReport } from '../../../lib/components';

export default function Image({ data, commonStyle, hotAreas, meta }) {
  const { src, lazyLoad } = data;

  if (!src || !src.trim()) {
    return <EmptyData />;
  }

  return (
    <div className="impage-material-image-container" style={{ ...commonStyle }}>
      {hotAreas}
      {lazyLoad ? (
        <LazyLoadImage
          src={src}
          meta={meta}
          className="impage-material-image"
          alt="[图片]"
          mode="widthFix"
          offset={250}
        />
      ) : (
        <ImgWithErrReport
          meta={meta}
          className="impage-material-image"
          src={src}
          alt="[图片]"
          mode="widthFix"
        />
      )}
    </div>
  );
}
