import React from 'react';
import classnames from 'classnames';
import { debounce } from '../../utils/debounce';
import { domVisible } from '../../utils/dom';
import { mpPageRendered } from '../../utils/firstPaint';
import { errorReport } from '../../utils';
import './index.scss';

const DEFAULT_DEBOUNCE_TIME = 40;
const LOAD_TIMEOUT = 2000;
const LOAD_DELAY_TIME = 200;

export default class LazyLoadImage extends React.Component {
  element = React.createRef();

  scrollEventHandle = null;

  state = {
    loaded: false,
  };

  componentDidMount() {
    const debounceWaitTime = parseInt(String(this.props.debounce), 10) || DEFAULT_DEBOUNCE_TIME;

    if (process.env.isMiniprogram) {
      this.miniprogramCheckVisible();
      return;
    }

    this.scrollEventHandle = debounce(this.checkVisible.bind(this), debounceWaitTime);
    window.addEventListener('scroll', this.scrollEventHandle);

    setTimeout(() => {
      this.checkVisible();
    });

    // 其他资源已经加载完成.直接都显示
    window.addEventListener('load', () => {
      setTimeout(() => {
        this.loadedHandler();
        this.removeListener();
      }, LOAD_DELAY_TIME);
    });
  }

  miniprogramCheckVisible() {
    mpPageRendered(() => {
      this.loadedHandler();
    });

    setTimeout(() => {
      this.loadedHandler();
    }, LOAD_TIMEOUT);
  }

  loadedHandler() {
    this.setState({
      loaded: true,
    });
  }

  /**
   * 判断当前元素是否处于可视化范围之内
   * @description {Boolean}
   */
  checkVisible() {
    if (!this.element) {
      return;
    }

    const { offset } = this.props;
    const { current } = this.element;
    const visible = domVisible(current, offset);

    if (visible) {
      this.removeListener();
      this.loadImage();
    }
  }

  loadImage() {
    const { src } = this.props;
    const image = new Image();

    image.onload = () => {
      this.loadedHandler();
    };

    image.onerror = () => {
      // aegis.report
    };

    image.src = src;
  }

  componentWillUnmount() {
    this.removeListener();
  }

  removeListener() {
    window.removeEventListener('scroll', this.scrollEventHandle);
  }

  render() {
    const { loaded } = this.state;
    const { className, ...props } = this.props;

    if (!loaded) {
      return (
        <div className={classnames('material-img skeleton', className)} ref={this.element} />
      );
    }

    return(
      <img
        className={classnames('material-img', className)}
        onError={e => {
          errorReport('image load error', {
            extra: {
              imgUrl: props.src,
              originErr: e,
              meta: props.meta,
            },
            tags: {
              componentName: 'image',
            },
          });
        }}
        {...props}
      />
    );
  }
}
