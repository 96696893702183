import * as React from 'react';
import { filterXSS } from 'xss';
import { EmptyData } from '../../lib/components';
import { ErrorBoundary } from '../../lib/utils';

function Text({ data, commonStyle }) {
  const { text } = data;

  if (!text || !text.trim()) {
    return <EmptyData />;
  }

  return (
    <p
      style={commonStyle}
      dangerouslySetInnerHTML={{ __html: filterXSS(text.replace(/[\n\r]/g, '<br/>')) }}
    />
  );
}

export default props => {
  return (
    <ErrorBoundary>
      <Text {...props} />
    </ErrorBoundary>
  );
};
