import React from 'react';
import { parseUrl } from './query-string'

export function preventSyntheticEvent(e) {
  e.preventDefault();
  e.stopPropagation();
  return false;
}

export function px(px) {
  return `${px}px`;
}

export function percent(percent) {
  return `${percent}%`;
}

export function isChildrenEmpty(children) {
  if (!children) {
    return true;
  }
  if (Array.isArray(children)) {
    return children.length <= 0;
  }
  return false;
}

export function getMetaDesc() {
  const metas = document.getElementsByTagName('meta');
  const meta = Array.from(metas).find(i => i.name.toLowerCase() === 'description');
  if (meta) {
    return meta.content;
  }
  return '';
}

export function createDataForm({ baseSchema, h5Schema, mpSchema }) {
  return ({ value, onChange, JSONSchemaForm }) => {
    const params = parseUrl(location.href);
    const containerType = params.query.type;
    let schema;
    if (containerType === 'mp') {
      schema = {
        ...baseSchema,
        ...mpSchema,
      };
    } else if (containerType === 'hybrid') {
      schema = {
        ...baseSchema,
        ...h5Schema,
        ...mpSchema,
      };
    } else {
      schema = {
        ...baseSchema,
        ...h5Schema,
      };
    }

    return <JSONSchemaForm value={value} onChange={onChange} schema={schema} />;
  }
}
