/* eslint-disable prefer-rest-params */

/**
 * 防抖
 * @see {@link https://github.com/twobin/react-lazyload/blob/master/src/utils/debounce.js}
 * @date 2020-11-15 20:12:34
 * @param {Function} func
 * @param {Number} wait
 * @param {Boolean} immediate
 */
function debounce(func, wait, immediate) {
  let timeout;
  let args;
  let context;
  let timestamp;
  let result;

  const later = function later() {
    const last = +new Date() - timestamp;

    if (last < wait && last >= 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) {
          context = null;
          args = null;
        }
      }
    }
  };

  return function debounced() {
    context = this;
    args = arguments;
    timestamp = +new Date();

    const callNow = immediate && !timeout;
    if (!timeout) {
      timeout = setTimeout(later, wait);
    }

    if (callNow) {
      result = func.apply(context, args);
      context = null;
      args = null;
    }

    return result;
  };
}

export { debounce };
