import * as React from 'react';
import { Modal as AntdModal } from 'antd';
import 'antd/lib/modal/style/css';
import './index.scss';
import { OPEN_DIALOG, CLOSE_DIALOG } from './const';
import IconClose from './icon_close.png';

/**
 * 弹窗组件
 */
export default function Modal({ data, commonStyle, children, emit, on, off, isEditing }) {
  const { keyboard, title, closable, mask,centered, maskClosable } = data;
  const [visible, setVisible] = React.useState(isEditing);

  React.useEffect(() => {
    setVisible(isEditing);
  }, [isEditing]);

  // 打开弹窗
  const handleOpenDialog = () => {
    if (!isEditing) {
      setVisible(true);
    }
    emit(OPEN_DIALOG);
  };

  // 处理取消事件
  const handleCancel = () => {
    if (!isEditing) {
      setVisible(false);
    }
    emit(CLOSE_DIALOG);
  };

  React.useEffect(() => {
    on(OPEN_DIALOG, handleOpenDialog);
    on(CLOSE_DIALOG, handleCancel);
    return () => {
      off && off(OPEN_DIALOG, handleOpenDialog);
      off && off(CLOSE_DIALOG, handleCancel);
    };
  }, []);
  const props = React.useMemo(() => {
    const option = {
      visible,
      title,
      keyboard,
      closable,
      maskClosable,
      centered
    };

    if (commonStyle && commonStyle.width) {
      option.width = commonStyle.width;
    }
    return option;
  }, [visible, title, keyboard, closable, maskClosable, commonStyle]);

  return (
    <AntdModal
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      mask={!isEditing && mask}
      getContainer={false}
      bodyStyle={{ maxHeight: commonStyle.height ? '' : '60vh', ...commonStyle }}
      className="ecard__modal"
      {...props}
      closeIcon={<img src={IconClose} height={24}/>}
    >
      {children}
    </AntdModal>
  );
}
