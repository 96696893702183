import { openWithUrlParams, open, deliverUrlParams } from '../../lib/utils';

function openURL({
  data: { newTable, url, deliverParams = true, deliverMPParams = true, mpUrl, isMpTab },
}) {
  if (process.env.isMiniprogram) {
    /**
     * 接收透传参数
     */

    const pageParams = wx.getStorageSync('VISION_GLOBAL_pageParams');

    if (deliverMPParams && pageParams) {
      console.log('要被透传的参数是:', pageParams);
      mpUrl = deliverUrlParams(mpUrl, pageParams);
      console.log('跳转的url是:', mpUrl);
    }

    const mpParams = {
      url: mpUrl,
      fail() {
        // switchTab 跳转失败时尝试 navigateTo
        if (isMpTab) {
          wx.navigateTo({ url: mpUrl });
        }
      },
    };
    return isMpTab ? wx.switchTab(mpParams) : wx.navigateTo(mpParams);
  }
  return deliverParams ? openWithUrlParams(url, newTable) : open(url, newTable);
}

// export default withErrReport(openURL);
export default openURL;
