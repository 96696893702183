// Import Libs and Handlers
import React from 'react';
import Renderer from '@tencent/gems-renderer-react';
import { withJimuComponentAdaptor } from '@tencent/gems-component-adaptor/esm/jimu';
import { injectStyle } from '../handlers/shared/injectStyle';
import { selectVersion } from '../handlers/shared/selectVersion';

// Import Components
import Image from '../libraries/base/components/Image';
import Modal from '../libraries/ecard/components/Modal';
import Text from '../libraries/base/components/Text'

// Import Plugins


// Import Actions
import OpenURL from '../libraries/base/actions/OpenURL'

let ReactDOMServer;
if(process.env.SSR) {
  // TODO: Remove
  require('@babel/polyfill/noConflict');
  ReactDOMServer = require('react-dom/server');
}

// Global Style
injectStyle(`body {
	border: 1px none #161616 !important;
background-color: #ffffff !important;
background-repeat: repeat-y !important;
background-size: auto !important;
background-position: center top !important;
}`, '__impage_injected-global-styles');

// Auto Inject Style
injectStyle(``, '__impage_injected-component-styles');

// Global Data
const meta = {
    "title": "腾讯教育电子学生证",
    "desc": "腾讯教育电子学生证",
    "start": new Date('Thu Aug 18 2022 20:51:21 GMT+0800 (China Standard Time)'),
    "end": new Date('Fri Sep 30 2022 20:51:21 GMT+0800 (China Standard Time)'),
    "actId": 9436,
    "actKey": "tencent",
    "containerType": "web",
    "isTemplate": false,
    "isEditor": false,
    "isTestEnv": false
};

const _global = {
    "faviconUrl": "//vision-1251316161.cos.ap-guangzhou.myqcloud.com/vision/favicon.ico"
};

// Inject JIMU_Vars
if (typeof window !== 'undefined') {
  if(!window['JIMU_VARS']){
    Object.defineProperty(window, 'JIMU_VARS', {
      value: {
    "key": "tencent",
    "actStartTime": "2022-08-18T12:51:21.000Z",
    "actEndTime": "2022-09-30T12:51:21.000Z",
    "isJimu": false,
    "faviconUrl": "//vision-1251316161.cos.ap-guangzhou.myqcloud.com/vision/favicon.ico"
},
      writable: false,
      configurable: false,
    });
  }

  // Inject Global
  if(!window['VISION']){
    Object.defineProperty(window, 'VISION', {
      value: { meta, global: _global },
      writable: false,
      configurable: false,
    });
  }
}

// Actions
const actions = {
    "1": {
        "action": OpenURL,
        "id": 1,
        "projId": 1,
        "projKey": "base",
        "name": "OpenURL",
        "info": {
            "name": "跳转URL",
            "author": "elliothu"
        }
    }
};

// Components
const components = {
    "5": {
        "component": Image,
        "id": 5,
        "projId": 1,
        "projKey": "base",
        "name": "Image",
        "info": {
            "name": "图片",
            "desc": "图片组件",
            "author": "elliothu"
        },
        "__implementHotArea": true
    },
    "7": {
        "component": Text,
        "id": 7,
        "projId": 1,
        "projKey": "base",
        "name": "Text",
        "info": {
            "name": "文本",
            "desc": "纯文本组件",
            "author": "elliothu"
        }
    },
    "336": {
        "component": Modal,
        "id": 336,
        "projId": 39,
        "projKey": "ecard",
        "name": "Modal",
        "info": {
            "name": "弹窗",
            "desc": "弹窗组件",
            "author": "dovli"
        }
    }
};

// Plugin
const plugins = {};

// SchemaMap
const schemaMap = {
    "vc7a68ef": {
        "components": [
            {
                "id": 5,
                "name": "Image",
                "key": 0,
                "data": {
                    "src": "//i.vision.qq.com/prod/imgs/static/static_1660876214937.jpg",
                    "lazyLoad": false,
                    "hotAreas": [
                        {
                            "key": 7,
                            "position": {
                                "x": 18.8,
                                "y": 68.08943089430895
                            },
                            "size": {
                                "width": 7.75,
                                "height": 12.398373983739837
                            },
                            "actions": [
                                {
                                    "id": 9007199254740991,
                                    "name": "emitComponentEventAction",
                                    "key": 15,
                                    "data": {
                                        "actionName": "__impage__component-event-openDialog"
                                    },
                                    "trigger": "__impage_action_trigger_click",
                                    "target": 9
                                }
                            ]
                        },
                        {
                            "key": 8,
                            "position": {
                                "x": 28.000001907348633,
                                "y": 68.40722682112354
                            },
                            "size": {
                                "width": 7.8125,
                                "height": 11.864406779661017
                            },
                            "actions": [
                                {
                                    "id": 9007199254740991,
                                    "name": "emitComponentEventAction",
                                    "key": 16,
                                    "data": {
                                        "actionName": "__impage__component-event-openDialog"
                                    },
                                    "trigger": "__impage_action_trigger_click",
                                    "target": 12
                                }
                            ]
                        }
                    ]
                },
                "style": {},
                "commonStyle": {
                    "width": "100.00%",
                    "cursor": "default",
                    "border": "1px none #161616"
                },
                "wrapperStyle": {
                    "backgroundColor": "transparent",
                    "backgroundRepeat": "repeat-y",
                    "backgroundSize": "auto",
                    "backgroundPosition": "center top"
                },
                "actions": [],
                "__implementHotArea": true,
                "fixedWrapperStyle": {}
            },
            {
                "id": 5,
                "name": "Image",
                "key": 1,
                "data": {
                    "src": "//i.vision.qq.com/prod/imgs/static/static_1660876250426.png",
                    "lazyLoad": false,
                    "hotAreas": []
                },
                "style": {},
                "commonStyle": {
                    "width": "100.00%",
                    "cursor": "default",
                    "border": "1px none #161616"
                },
                "wrapperStyle": {
                    "backgroundColor": "transparent",
                    "backgroundRepeat": "repeat-y",
                    "backgroundSize": "auto",
                    "backgroundPosition": "center top"
                },
                "actions": [],
                "__implementHotArea": true,
                "fixedWrapperStyle": {}
            },
            {
                "id": 5,
                "name": "Image",
                "key": 2,
                "data": {
                    "src": "//i.vision.qq.com/prod/imgs/static/static_1660876269348.png",
                    "lazyLoad": false,
                    "hotAreas": []
                },
                "style": {},
                "commonStyle": {
                    "width": "100.00%",
                    "cursor": "default",
                    "border": "1px none #161616"
                },
                "wrapperStyle": {
                    "backgroundColor": "transparent",
                    "backgroundRepeat": "repeat-y",
                    "backgroundSize": "auto",
                    "backgroundPosition": "center top"
                },
                "actions": [],
                "__implementHotArea": true,
                "fixedWrapperStyle": {}
            },
            {
                "id": 5,
                "name": "Image",
                "key": 3,
                "data": {
                    "src": "//i.vision.qq.com/prod/imgs/static/static_1660876303252.png",
                    "lazyLoad": false,
                    "hotAreas": []
                },
                "style": {},
                "commonStyle": {
                    "width": "100.00%",
                    "cursor": "default",
                    "border": "1px none #161616"
                },
                "wrapperStyle": {
                    "backgroundColor": "transparent",
                    "backgroundRepeat": "repeat-y",
                    "backgroundSize": "auto",
                    "backgroundPosition": "center top"
                },
                "actions": [],
                "__implementHotArea": true,
                "fixedWrapperStyle": {}
            },
            {
                "id": 5,
                "name": "Image",
                "key": 4,
                "data": {
                    "src": "//i.vision.qq.com/prod/imgs/static/static_1660876328239.png",
                    "lazyLoad": false,
                    "hotAreas": []
                },
                "style": {},
                "commonStyle": {
                    "width": "100.00%",
                    "cursor": "default",
                    "border": "1px none #161616"
                },
                "wrapperStyle": {
                    "backgroundColor": "transparent",
                    "backgroundRepeat": "repeat-y",
                    "backgroundSize": "auto",
                    "backgroundPosition": "center top"
                },
                "actions": [],
                "__implementHotArea": true,
                "fixedWrapperStyle": {}
            },
            {
                "id": 5,
                "name": "Image",
                "key": 5,
                "data": {
                    "src": "//i.vision.qq.com/prod/imgs/static/static_1660876347735.jpg",
                    "lazyLoad": false,
                    "hotAreas": []
                },
                "style": {},
                "commonStyle": {
                    "width": "100.00%",
                    "cursor": "default",
                    "border": "1px none #161616"
                },
                "wrapperStyle": {
                    "backgroundColor": "transparent",
                    "backgroundRepeat": "repeat-y",
                    "backgroundSize": "auto",
                    "backgroundPosition": "center top"
                },
                "actions": [],
                "__implementHotArea": true,
                "fixedWrapperStyle": {}
            },
            {
                "id": 5,
                "name": "Image",
                "key": 6,
                "data": {
                    "src": "//i.vision.qq.com/prod/imgs/static/static_1660876370569.png",
                    "lazyLoad": false,
                    "hotAreas": [
                        {
                            "key": 17,
                            "position": {
                                "x": 18.75,
                                "y": 37.67441860465116
                            },
                            "size": {
                                "width": 2.9499999999999993,
                                "height": 7.441860465116279
                            },
                            "actions": [
                                {
                                    "id": 1,
                                    "name": "OpenURL",
                                    "key": 21,
                                    "data": {
                                        "newTable": true,
                                        "deliverParams": true,
                                        "url": "https://docs.qq.com/doc/DTkdiZUtNd0paeHJL"
                                    },
                                    "trigger": "__impage_action_trigger_click",
                                    "target": null
                                }
                            ]
                        },
                        {
                            "key": 18,
                            "position": {
                                "x": 22.35,
                                "y": 37.67441860465116
                            },
                            "size": {
                                "width": 2.9000000000000004,
                                "height": 7.441860465116279
                            },
                            "actions": [
                                {
                                    "id": 1,
                                    "name": "OpenURL",
                                    "key": 22,
                                    "data": {
                                        "newTable": true,
                                        "deliverParams": true,
                                        "url": "https://edu.tencent.com/about/"
                                    },
                                    "trigger": "__impage_action_trigger_click",
                                    "target": null
                                }
                            ]
                        },
                        {
                            "key": 19,
                            "position": {
                                "x": 25.85,
                                "y": 37.67441860465116
                            },
                            "size": {
                                "width": 3.0000000000000004,
                                "height": 7.441860465116279
                            },
                            "actions": [
                                {
                                    "id": 1,
                                    "name": "OpenURL",
                                    "key": 23,
                                    "data": {
                                        "newTable": true,
                                        "deliverParams": true,
                                        "url": "https://docs.qq.com/doc/DTkhXQmdvZkNYdEtE"
                                    },
                                    "trigger": "__impage_action_trigger_click",
                                    "target": null
                                }
                            ]
                        },
                        {
                            "key": 20,
                            "position": {
                                "x": 29.599999999999998,
                                "y": 37.2093023255814
                            },
                            "size": {
                                "width": 3.0000000000000004,
                                "height": 8.372093023255815
                            },
                            "actions": [
                                {
                                    "id": 1,
                                    "name": "OpenURL",
                                    "key": 24,
                                    "data": {
                                        "newTable": true,
                                        "deliverParams": true,
                                        "url": "https://docs.qq.com/doc/DTkVOQWFUY01qU0Vy"
                                    },
                                    "trigger": "__impage_action_trigger_click",
                                    "target": null
                                }
                            ]
                        }
                    ]
                },
                "style": {},
                "commonStyle": {
                    "width": "100.00%",
                    "cursor": "default",
                    "border": "1px none #161616"
                },
                "wrapperStyle": {
                    "backgroundColor": "transparent",
                    "backgroundRepeat": "repeat-y",
                    "backgroundSize": "auto",
                    "backgroundPosition": "center top"
                },
                "actions": [],
                "__implementHotArea": true,
                "fixedWrapperStyle": {}
            },
            {
                "id": 336,
                "name": "Modal",
                "key": 9,
                "data": {
                    "closable": true,
                    "keyboard": true,
                    "title": "家长端",
                    "centered": true
                },
                "style": {},
                "commonStyle": {
                    "width": "560px",
                    "height": "452px",
                    "padding": "20px 20px 20px 20px",
                    "color": "#161616",
                    "fontSize": "14px",
                    "lineHeight": "20px",
                    "textAlign": "center",
                    "fontWeight": "normal",
                    "border": "1px none #161616",
                    "backgroundColor": "transparent",
                    "backgroundRepeat": "repeat-y",
                    "backgroundSize": "auto",
                    "backgroundPosition": "center top"
                },
                "wrapperStyle": {},
                "actions": [],
                "children": [
                    {
                        "id": 7,
                        "name": "Text",
                        "key": 10,
                        "data": {
                            "text": "微信扫码添加"
                        },
                        "style": {},
                        "commonStyle": {
                            "maxWidth": "100%",
                            "margin": "0px 0px 32px 0px",
                            "color": "#161616",
                            "fontSize": "32px",
                            "lineHeight": "48px",
                            "textAlign": "center",
                            "fontWeight": "normal",
                            "border": "1px none #161616",
                            "backgroundColor": "transparent",
                            "backgroundRepeat": "repeat-y",
                            "backgroundSize": "auto",
                            "backgroundPosition": "center top"
                        },
                        "wrapperStyle": {},
                        "actions": [],
                        "fixedWrapperStyle": {}
                    },
                    {
                        "id": 5,
                        "name": "Image",
                        "key": 11,
                        "data": {
                            "src": "//i.vision.qq.com/prod/imgs/static/static_1660825153122.png?imageView2/2/w/2000/format/webp/q/80!",
                            "lazyLoad": false,
                            "hotAreas": []
                        },
                        "style": {},
                        "commonStyle": {
                            "width": "280px",
                            "height": "280px",
                            "margin": "0px auto 60px auto",
                            "cursor": "default",
                            "border": "1px none #161616"
                        },
                        "wrapperStyle": {
                            "backgroundColor": "transparent",
                            "backgroundRepeat": "repeat-y",
                            "backgroundSize": "auto",
                            "backgroundPosition": "center top"
                        },
                        "actions": [],
                        "__implementHotArea": true,
                        "fixedWrapperStyle": {}
                    }
                ],
                "fixedWrapperStyle": {}
            },
            {
                "id": 336,
                "name": "Modal",
                "key": 12,
                "data": {
                    "closable": true,
                    "keyboard": true,
                    "title": "教师端",
                    "centered": true
                },
                "style": {},
                "commonStyle": {
                    "width": "560px",
                    "height": "452px",
                    "padding": "20px 20px 20px 20px",
                    "color": "#161616",
                    "fontSize": "14px",
                    "lineHeight": "20px",
                    "textAlign": "center",
                    "fontWeight": "normal",
                    "border": "1px none #161616",
                    "backgroundColor": "transparent",
                    "backgroundRepeat": "repeat-y",
                    "backgroundSize": "auto",
                    "backgroundPosition": "center top"
                },
                "wrapperStyle": {},
                "actions": [],
                "children": [
                    {
                        "id": 7,
                        "name": "Text",
                        "key": 13,
                        "data": {
                            "text": "微信扫码添加"
                        },
                        "style": {},
                        "commonStyle": {
                            "maxWidth": "100%",
                            "margin": "0px 0px 32px 0px",
                            "color": "#161616",
                            "fontSize": "32px",
                            "lineHeight": "48px",
                            "textAlign": "center",
                            "fontWeight": "normal",
                            "border": "1px none #161616",
                            "backgroundColor": "transparent",
                            "backgroundRepeat": "repeat-y",
                            "backgroundSize": "auto",
                            "backgroundPosition": "center top"
                        },
                        "wrapperStyle": {},
                        "actions": [],
                        "fixedWrapperStyle": {}
                    },
                    {
                        "id": 5,
                        "name": "Image",
                        "key": 14,
                        "data": {
                            "src": "//i.vision.qq.com/prod/imgs/static/static_1660825272267.png?imageView2/2/w/2000/format/webp/q/80!",
                            "lazyLoad": false,
                            "hotAreas": []
                        },
                        "style": {},
                        "commonStyle": {
                            "width": "280px",
                            "height": "280px",
                            "margin": "0px auto 60px auto",
                            "cursor": "default",
                            "border": "1px none #161616"
                        },
                        "wrapperStyle": {
                            "backgroundColor": "transparent",
                            "backgroundRepeat": "repeat-y",
                            "backgroundSize": "auto",
                            "backgroundPosition": "center top"
                        },
                        "actions": [],
                        "__implementHotArea": true,
                        "fixedWrapperStyle": {}
                    }
                ],
                "fixedWrapperStyle": {}
            }
        ],
        "plugins": [],
        "version": {
            "versionId": "vc7a68ef",
            "versionName": "default"
        }
    }
};

export default function App({ version }) {
  const schema = selectVersion(schemaMap, version);
  return (
    <Renderer
      schema={schema}
      materials={{ components, plugins, actions }}
      autoParseStyle={false}
      global={_global}
      meta={meta}
      withComponentAdaptor={withJimuComponentAdaptor}
    />
  );
}

export function renderToString() {
  return ReactDOMServer.renderToString(<App />);
}
