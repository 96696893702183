const POLL_TIME = 10;
const PAGE_RENDERED = 'pageRendered';

/**
 * 模拟首屏开始渲染时间
 * @date 2020-08-28 00:44:31
 */
function mockPageFirstPaintTime(callback) {
  let timer = null;

  function mockFirstPaintTime() {
    const app = document.querySelector('#app');
    if (!app) {
      timer = setTimeout(() => {
        mockFirstPaintTime();
      }, POLL_TIME);
      return;
    }

    app
      .$$getBoundingClientRect()
      .then(rect => {
        if (rect && rect.width && rect.height) {
          callback();
          clearTimeout(timer);
          timer = null;

          /**
           * 首屏渲染完成
           * @author alawnxu
           * @date 2020-10-27 15:35:39
           */
          window.VISION.global.rendered = true;
          window.$$trigger(PAGE_RENDERED, {
            event: {},
          });
          return;
        }

        timer = setTimeout(() => {
          mockFirstPaintTime();
        }, POLL_TIME);
      })
      .catch(_ => {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
      });
  }

  mockFirstPaintTime();
}

/**
 * 获取首屏开始渲染时间
 * @date 2020-08-28 00:43:22
 * @param {Function} callback 回调函数
 */
function getFirstPaintTime(callback) {
  let exec = false;

  callback = typeof callback === 'function' ? callback : () => {};
  const funCallback = () => {
    if (exec) {
      return;
    }

    exec = true;
    callback();
  };

  mockPageFirstPaintTime(funCallback);
}

/**
 * 小程序渲染完成
 * @param {Function} callback 回调函数
 */
function mpPageRendered(callback) {
  callback = typeof callback === 'function' ? callback : () => {};
  if (window.VISION.global.rendered) {
    callback();
    return;
  }

  window.addEventListener(PAGE_RENDERED, () => {
    callback();
  });
}

export { getFirstPaintTime, mpPageRendered };
