export function injectStyle(content, className) {
  if (typeof window === 'undefined') {
    return;
  }
  if (!content.toString().trim()) {
    return;
  }
  if (process.env.isMiniprogram) {
    // 小程序动态注入采用变通的方法
    // 1.分析出样式块 2.选择元素实例 3.设置该实例样式
    setTimeout(() => {
      const styleRegExp = /\s*([^{]*?)\s*\{\s*([^}]*?)\s*\}\s*/g;
      const setStyle = (element, style) => {
        element.setAttribute('style', style);
      };
      let match = styleRegExp.exec(content);
      let selector;
      let elements;
      while (match !== null) {
        selector = match[1];
        if (selector === 'body') {
          elements = [document.body];
        } else {
          elements = document.querySelectorAll(match[1]);
        }
        for (let i = 0; i < elements.length; i++) {
          setStyle(elements[i], match[2]);
        }
        match = styleRegExp.exec(content);
      }
    }, 100);
  } else if (typeof document !== 'undefined') {
    const style = document.createElement('style');
    style.setAttribute('type', 'text/css');
    style.setAttribute('class', className);
    style.innerHTML = content;
    document.head.appendChild(style);
  }
}
