/**
 * 判断当前元素是否出现在视窗范围.相对于body
 * @param {HTMLElement} node 节点
 * @param {Number} offset 缓冲区域
 */
function domVisible(node, offset = 0) {
  if (process.env.isMiniprogram) {
    return;
  }

  if (!(node instanceof HTMLElement)) {
    return;
  }

  if (!(node.offsetWidth || node.offsetHeight || node.getClientRects().length)) {
    return false;
  }

  const { top, height } = node.getBoundingClientRect();
  const windowInnerHeight = window.innerHeight || window.document.documentElement.clientHeight;

  return (top - offset <= windowInnerHeight) && (top + height + offset >= 0);
}

export { domVisible };
