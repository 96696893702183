import * as React from 'react';
import * as Sentry from '@sentry/browser';

function initSentry() {
  Sentry.init({
    dsn: 'https://f1921064e90a42cd90428e2bd660547d@report.url.cn/sentry/2512',
    release: 'f16a6c6c9b6111eaa5b0ec388f70eb79',
    beforeSend: (event, hint) => {
      if (event.extra && event.extra.isBaseLib) {
        return event;
      }

      console.log('not isBaseLib');
      return null;
    },
  });
}

const isMiniprogram = process.env.isMiniprogram;

if (!isMiniprogram) {
  initSentry();
}

function reportToSentry(error, context = {}) {
  if (isMiniprogram) {
    //小程序暂不支持 sentry 上报
    return;
  }

  Sentry.withScope(scope => {
    scope.setTags(context.tags);
    scope.setExtras({ ...context.extra, isBaseLib: true });
    Sentry.captureException(error);
  });
}

function errorReport(error, context = {}) {
  console.log('start error report:', error, context);
  reportToSentry(error, context);
  console.log('error report success');
}

function withErrReport(fn) {
  return async (...argus) => {
    try {
      await fn(...argus);
    } catch (e) {
      const { meta } = argus[0];
      errorReport(e, {
        extra: {
          meta,
        },
        tags: {
          componentName: fn.name || 'unknown',
        },
      });
    }
  };
}

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    const { props = {}, type = {} } = this.props.children;
    const { meta } = props;

    errorReport(error, {
      extra: {
        meta,
      },
      tags: {
        componentName: type.name || 'unknown',
      },
    });
  }
  render() {
    return this.props.children;
  }
}

export { errorReport, withErrReport, ErrorBoundary };
